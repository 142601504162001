"use client";

import KeyboardBackspaceSharpIcon from "@mui/icons-material/KeyboardBackspaceSharp";
import { AppBar, Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import Link from "next/link";

import { createRef, useEffect, useState } from "react";

interface AdjustedNavigationProps {
  headerLabel: string;
  enableFading?: boolean;
  slug: string;
}

/**
 * React component to display the main header navigation.
 * Utilizes NavigationItem components to display the actual items.
 *
 * @param {ContentfulNavigation} items
 * @returns JSX.Element
 */
const AdjustedNavigation = ({
  headerLabel,
  enableFading,
  slug,
}: AdjustedNavigationProps) => {
  const ref = createRef<HTMLDivElement>();
  const theme = useTheme();
  const [hasScrolled, setHasScrolled] = useState<boolean>(!enableFading);
  const [navigationHeight, setNavigationHeight] = useState<number>(54);
  const [offsetTop, setOffsetTop] = useState<number>(0);

  useScrollPosition(
    ({ currPos }) => {
      if (!enableFading) {
        if (!hasScrolled) {
          setHasScrolled(true);
        }
        return;
      }
      if (currPos.y * -1 > offsetTop) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    },
    [hasScrolled, enableFading]
  );

  useEffect(() => {
    setNavigationHeight(ref.current?.offsetHeight || 54);
    if (!enableFading) {
      setHasScrolled(true);
    }
    console.log(ref.current?.offsetHeight, navigationHeight);
    setOffsetTop(ref.current?.getBoundingClientRect().top || 0);
  }, [ref.current?.offsetHeight, enableFading, ref]);

  const navigationColors = theme.components?.AdjustedNavigation!;

  return (
    <AppBar
      ref={ref}
      sx={{
        position: "sticky",
        boxSizing: "content-box",
        backgroundColor: hasScrolled
          ? navigationColors.backgroundColor
          : "transparent",
        transition: "background-color 0.3s ease-in-out",
        zIndex: 10,
        boxShadow: hasScrolled
          ? "0px 2px 0px rgba(209, 209, 209, 0.2)"
          : "none",
        ...(enableFading && { marginBottom: `-${navigationHeight}px` }),
      }}
    >
      <Box
        sx={{
          maxWidth: 1280,
          width: "100%",
          marginX: "auto",
          paddingX: {
            xs: theme.spacing(2),
            md: theme.spacing(5),
          },
          boxSizing: "border-box",
        }}
      >
        <Grid container sx={{ minHeight: { xs: 40, md: 54 } }}>
          <Link
            href={slug}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "2px",
              textDecoration: "none", // Remove underline
              color: navigationColors.color,
            }}
          >
            <KeyboardBackspaceSharpIcon />
            <Typography
              variant="body1"
              sx={{
                color: navigationColors.color,
              }}
            >
              {headerLabel}
            </Typography>
          </Link>
        </Grid>
      </Box>
    </AppBar>
  );
};

export default AdjustedNavigation;

"use client";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  IconButton,
  List,
  ListItemText,
  Link as MUILink,
  MenuItem,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { styled, useTheme } from "@mui/material/styles";

import dynamic from "next/dynamic";
import { usePathname } from "next/navigation";

import { useState } from "react";

import {
  ContentfulNavigation,
  ContentfulNavigationPageReference,
} from "../../../../@types/contentfulNavigation";
import { getHrefByPageTypeAndSlug } from "../../../../utils/helperFunctions";
import { saveCoursepageSource } from "../../../../utils/localstorage/navigationTracking";
import { COURSE_LISTS } from "../../../../utils/trackingEvents/select-item";

interface INavMobile {
  items: ContentfulNavigation;
}

/**
 * Mobile Navigation. Composed of Mui Drawer, a list of MenuItems and corresponding SubmenuItems
 * If navigation item has no children, the MenuItem links directly to page
 *
 * @param {ContentfulNavigation} items - navigation items
 * @returns JSX.Element
 */
const NavMobile = ({ items }: INavMobile) => {
  const theme = useTheme();
  const path = usePathname();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openSubMenuItem, setOpenSubMenuItem] =
    useState<ContentfulNavigation | null>(null);

  const navItemList = () => {
    const navItems = openSubMenuItem
      ? openSubMenuItem.subNavCollection
      : items.subNavCollection;

    const updateMenu = (item: ContentfulNavigation) => {
      setOpenSubMenuItem(item);
    };

    const handleItemClick = (index: number, item: any) => {
      if (!openSubMenuItem) {
        return updateMenu(item);
      }
      return null;
    };

    return (
      <List>
        {navItems.items.map((item: ContentfulNavigation, index: number) => {
          // MenuItems with submenu
          if (
            item.subNavCollection &&
            item.subNavCollection.items?.length > 0
          ) {
            return (
              <MenuItem
                key={`mob-${item.internal}`}
                onClick={() => handleItemClick(index, item)}
                sx={{
                  borderRadius: "100px",
                }}
              >
                <ListItemText
                  primary={item.label}
                  sx={{
                    p: theme.spacing(2),
                  }}
                  primaryTypographyProps={{
                    variant: "body2",
                    color: theme.components?.Navigation.colorListItem,
                  }}
                />
                {!openSubMenuItem && <ChevronRightIcon />}
              </MenuItem>
            );
          }

          // MenuItems without submenu
          const { pageReference, externalLink, openNewTab, internal } = item;
          let fullHref = "/";
          let selected = false;
          if (pageReference) {
            const { __typename, slug, isRecipe } =
              pageReference as ContentfulNavigationPageReference;
            fullHref = getHrefByPageTypeAndSlug(__typename, slug, isRecipe);
            selected = path === fullHref;
          } else if (externalLink) {
            fullHref = externalLink;
          }
          let isHighlighted = item.label == "Weihnachts-Box";

          return (
            <MenuItem
              key={`mob-${internal}`}
              onClick={() => {
                saveCoursepageSource(COURSE_LISTS.HEADER);
                setIsDrawerOpen(false);
              }}
              sx={{
                borderRadius: "100px",
                "&.Mui-selected": {
                  backgroundColor:
                    theme.components?.Navigation.backgroundColorSelected,
                },
              }}
              selected={selected}
            >
              <MUILink
                sx={{
                  p: theme.spacing(2),
                  display: "block",
                  width: "100%",
                }}
                variant="body2"
                color={
                  isHighlighted ? "white" : theme.components?.Navigation.color
                }
                target={openNewTab ? "_blank" : "_self"}
                underline="none"
                href={fullHref}
              >
                {isHighlighted && (
                  <Box
                    sx={{
                      backgroundColor: "#ff7e8f",
                      position: "absolute",
                      left: "-0px",
                      width: "100%",
                      height: "40px",
                      top: "12px",
                      borderRadius: "25px",
                      zIndex: -1,
                    }}
                  ></Box>
                )}
                {item.label}
              </MUILink>
            </MenuItem>
          );
        })}
      </List>
    );
  };

  /**
   * Drawer
   */
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setIsDrawerOpen(open);
    };

  const drawerWidth = 376;
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  return (
    <>
      <IconButton
        size="large"
        aria-label="Navigation"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={toggleDrawer(true)}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <nav>
        <Drawer
          id="menu-appbar"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
          anchor="right"
          variant="temporary"
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            width: drawerWidth,
            maxWidth: "100vw",
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              maxWidth: "100vw",
            },
          }}
        >
          <DrawerHeader>
            {/* Drawer close button */}
            <IconButton onClick={toggleDrawer(false)} size="large">
              <CloseIcon
                sx={{ color: theme.components?.Navigation.colorListItem }}
              />
            </IconButton>
          </DrawerHeader>
          <Box sx={{ p: 2 }}>
            {openSubMenuItem && (
              // Back Link with chevron left and label
              <MenuItem
                onClick={() => {
                  setOpenSubMenuItem(null);
                }}
                sx={{
                  py: 1,
                  px: 2,
                }}
              >
                <ChevronLeftIcon sx={{ ml: -1, mr: 1 }} />
                <ListItemText
                  primary={openSubMenuItem.label}
                  primaryTypographyProps={{
                    variant: "body1",
                    fontWeight: 700,
                  }}
                />
              </MenuItem>
            )}
            {navItemList()}
          </Box>
        </Drawer>
      </nav>
    </>
  );
};

export default NavMobile;

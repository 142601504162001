import { ShopifyProductWithVariant } from "../../@types/shopifyProductWithVariant";
import { getShopifyProductsFromHref } from "../helperFunctions";
import { DataLayerItem, clearDataLayer, getDataLayerItem } from "./common";

interface BeginCheckoutEvent {
  event: string;
  ecommerce: {
    currency: string;
    totalValue: number;
    items: DataLayerItem[];
  };
}

const EVENT_NAME = "begin_checkout";

export const pushDataLayerBeginCheckout = (
  shopifyProducts: ShopifyProductWithVariant[]
) => {
  if (typeof window !== "undefined" && window.dataLayer) {
    clearDataLayer();
    window.dataLayer.push(getDataLayerEvent(shopifyProducts));
  }
};

function getDataLayerEvent(
  shopifyProducts: ShopifyProductWithVariant[]
): BeginCheckoutEvent {
  const obj = {
    event: EVENT_NAME,
    ecommerce: {
      currency: shopifyProducts[0]?.minPrice.currencyCode,
      totalValue: shopifyProducts.reduce(
        (prev, curr) =>
          prev +
          (curr.hasOwnProperty("price") && curr.price !== undefined
            ? parseFloat(curr.price.amount)
            : parseFloat(curr.minPrice.amount)),
        0
      ),
      items: shopifyProducts.map((shopifyProduct) =>
        getDataLayerItem(shopifyProduct)
      ),
    },
  };
  return obj;
}

/**
 *  Temporary solution to write checkout events for direct checkout links
 *
 * @param href link in the form of https://shop.hellohealth.de/cart/44765250421001:1,44497963614473:1,44497855578377:1,44337835639049:1?discount=DCFLCK30
 */
export const pushDataLayerBeginCheckoutFromString = (href: string) => {
  if (typeof window !== "undefined" && window.dataLayer) {
    clearDataLayer();

    const items = getShopifyProductsFromHref(href);
    const dataLayerEvent = {
      event: EVENT_NAME,
      ecommerce: {
        currency: "EUR",
        totalValue: items.reduce(
          (prev, curr) => prev + parseFloat(curr.price.amount),
          0
        ),
        items,
      },
    };
    window.dataLayer.push(dataLayerEvent);
  }
};

export const pushDataLayerBeginCheckoutFromProductId = (
  items: DataLayerItem[],
  totalValue: number
) => {
  if (typeof window !== "undefined" && window.dataLayer) {
    clearDataLayer();

    const dataLayerEvent = {
      event: EVENT_NAME,
      ecommerce: {
        currency: "EUR",
        totalValue,
        items,
      },
    };
    window.dataLayer.push(dataLayerEvent);
  }
};

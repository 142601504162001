"use client";

import {
  AppBar,
  Box,
  Grid,
  List,
  Link as MUILink,
  MenuItem,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { Asset } from "contentful";

import Image from "next/legacy/image";
import Link from "next/link";

import { createRef, useEffect, useState } from "react";

import { ContentfulNavigation } from "../../../@types/contentfulNavigation";
import { baskastTheme } from "../../../styles/globalTheme";
import { withHttps } from "../../../utils/helperFunctions";
import { DOC_FLECK_TENANT_NAME } from "../../../utils/tenant-names";
import CartNavItem from "../../cart/cart-nav-item";
import NavDesktop from "./desktop";
import NavMobile from "./mobile";

interface NavigationProps {
  /**
   * Whether to show content in the navigation. If false, only the logo will be displayed and the navigation won't have a sticky position
   */
  noNavigation: boolean;
  /**
   * Contentful navigation items from a GraphQL query.
   */
  items: ContentfulNavigation;
  /**
   * Logo
   */
  logo?: Asset;
  /**
   * Alt Logo
   */
  logoAlt?: Asset;

  enableFading?: boolean;
  tenant?: string;
}

/**
 * React component to display the main header navigation.
 * Utilizes NavigationItem components to display the actual items.
 *
 * @param {ContentfulNavigation} items
 * @returns JSX.Element
 */
const Navigation = ({
  noNavigation,
  items,
  logo,
  logoAlt: _logoAlt,
  enableFading,
  tenant,
}: NavigationProps) => {
  const ref = createRef<HTMLDivElement>();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [hasScrolled, setHasScrolled] = useState<boolean>(!enableFading);
  const [navigationHeight, setNavigationHeight] = useState<number>(81);
  const [offsetTop, setOffsetTop] = useState<number>(0);
  const logoAlt = theme === baskastTheme ? logo : _logoAlt;

  const cartNavItem = <CartNavItem />;

  useScrollPosition(
    ({ currPos }) => {
      if (!enableFading) {
        if (!hasScrolled) {
          setHasScrolled(true);
        }
        return;
      }
      if (currPos.y * -1 > offsetTop) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    },
    [hasScrolled, enableFading]
  );

  useEffect(() => {
    setNavigationHeight(ref.current?.offsetHeight || 81);
    if (!enableFading) {
      setHasScrolled(true);
    }
    setOffsetTop(ref.current?.getBoundingClientRect().top || 0);
  }, [ref.current?.offsetHeight, enableFading, ref]);

  const logoVariant = hasScrolled ? logo : logoAlt;

  const logoUrl = logoVariant?.fields.file.url;
  const navigationColors = theme.components?.Navigation!;
  return (
    <AppBar
      ref={ref}
      sx={{
        position: noNavigation ? "relative" : "sticky",
        boxSizing: "content-box",
        backgroundColor:
          hasScrolled && !noNavigation
            ? navigationColors.backgroundColor
            : "transparent",
        transition: "background-color 0.3s ease-in-out",
        color:
          hasScrolled && !noNavigation
            ? navigationColors.color
            : navigationColors.colorFaded,
        zIndex: 10,
        boxShadow:
          hasScrolled && !noNavigation
            ? "0px 2px 0px rgba(209, 209, 209, 0.2)"
            : "none",
        ...(enableFading && { marginBottom: `-${navigationHeight}px` }),
      }}
    >
      <Box
        sx={{
          maxWidth: 1280,
          width: "100%",
          marginX: "auto",
          paddingX: {
            xs: theme.spacing(2),
            md: theme.spacing(5),
          },
          boxSizing: "border-box",
        }}
      >
        <Grid container>
          <Toolbar
            disableGutters
            sx={{ minHeight: { xs: 64, md: 80 }, width: "100%" }}
          >
            <Box
              sx={{
                display: "flex",
                mr: 1,
                width: "100%",
                maxWidth: "144px",
                position: "relative",
              }}
            >
              {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
              {logoUrl &&
                (noNavigation ? (
                  <Image
                    src={withHttps(logoUrl)}
                    width={logoVariant?.fields.file.details.image?.width || 110}
                    height={
                      logoVariant?.fields.file.details.image?.height || 55
                    }
                    alt="Logo"
                  />
                ) : (
                  <Link href="/">
                    <Image
                      src={withHttps(logoUrl)}
                      width={
                        logoVariant?.fields.file.details.image?.width || 110
                      }
                      height={
                        logoVariant?.fields.file.details.image?.height || 55
                      }
                      alt="Logo"
                    />
                  </Link>
                ))}
            </Box>

            {!noNavigation && (
              <List
                sx={{
                  flexGrow: 1,
                  display: { xs: "flex", md: "none" },
                  justifyContent: "flex-end",
                  mr: 0,
                }}
              >
                <NavMobile items={items} />
                {isSmallScreen &&
                  tenant === DOC_FLECK_TENANT_NAME &&
                  cartNavItem}
              </List>
            )}
            {!noNavigation && (
              <List
                sx={{
                  flexGrow: 1,
                  justifyContent: "flex-end",
                  display: { xs: "none", md: "flex" },
                  p: 0,
                  height: "100%",
                }}
              >
                <NavDesktop
                  showHighlight={
                    tenant === DOC_FLECK_TENANT_NAME && enableFading
                  }
                  items={items}
                  isWhiteText={!hasScrolled}
                />

                {!isSmallScreen &&
                  tenant === DOC_FLECK_TENANT_NAME &&
                  cartNavItem}
              </List>
            )}
          </Toolbar>
        </Grid>
      </Box>
    </AppBar>
  );
};

export default Navigation;
